
<template>
  <TransitionRoot as="template" :show="true">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-50" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0 bg-black opacity-40" />
        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="pointer-events-auto w-screen max-w-md">
              <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div class="h-0 flex-1 overflow-y-auto">
                  <div class="bg-gradient-to-r from-indigo-600 to-purple-600 py-6 px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">Zarządzaj producentem</DialogTitle>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">Zarządzaj producentem {{ item.name }}</p>
                    </div>
                  </div>

                <div>
                    <!-- name -->
                    <div class="px-4 sm:px-6 lg:px-8 mt-5">
                        <label for="name" class="block text-sm font-medium text-gray-700">Nazwa<span class="text-red-500">*</span></label>
                        <div class="mt-1">
                            <input  v-model="manufacturer.name" type="text" name="name" id="name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                        <div v-if="errors.name" class="text-red-500 text-xs ml-1 mt-1">{{errors.name}}</div>
                    </div>
                    
                    <label class="block text-sm font-medium text-gray-500 px-6 mb-2 select-none">Podstawowe informacje</label>
                    <div class="relative">
                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                            <div class="w-full border-t border-gray-300" />
                        </div>
                        <div class="relative flex justify-center">
                            <span class="bg-white px-2 text-sm text-gray-500"></span>
                        </div>
                    </div>

                    <!-- email -->
                    <div class="px-6 mt-5">
                        <label for="email" class="block text-sm font-medium text-gray-700">Email<span class="text-red-500">*</span></label>
                        <div class="mt-1">
                            <input  v-model="manufacturer.email" type="text" name="email" id="email" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                        <div v-if="errors.email" class="text-red-500 text-xs ml-1 mt-1">{{errors.email}}</div>
                    </div>

                    <!-- phone -->
                    <div class="px-6 mt-2 mb-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Telefon</label>
                        <div class="mt-1 col-span-2 flex flex-col">
                            <div class="grid grid-cols-4">
                            <div>
                                <Listbox v-model="manufacturer.phone.prefix" as="div">
                                    <div class="relative">
                                    <ListboxButton class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                        <span class="flex items-center">
                                            <span class="ml-1 block truncate">{{manufacturer.phone.prefix}}</span>
                                        </span>
                                        <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                            <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </ListboxButton>

                                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                        <ListboxOptions class="absolute z-10 mt-1 max-h-56  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            <ListboxOption as="template" v-for="country in phonePrefixes" :key="country.name" :value="country.prefix" v-slot="{ active, selected }">
                                                <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                    <div class="flex items-center">
                                                        <img :src="country.ref.flags.png" class="h-6 w-6 flex-shrink-0 rounded-full" />
                                                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{ country.prefix }} <span class="text-xs">({{country.name}})</span></span>
                                                    </div>

                                                    <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                </li>
                                            </ListboxOption>
                                        </ListboxOptions>
                                    </transition>
                                    </div>
                                </Listbox>
                            </div>
                            <div class="flex col-span-3 items-center rounded-md shadow-sm ml-2">
                                <input v-model="manufacturer.phone.number" type="text" maxlength="12" class="flex-1 block w-full py-2 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            </div>
                        </div>
                    </div>

                    <label class="block text-sm font-medium text-gray-500 px-6 mb-2 select-none">Adres producenta</label>
                    <div class="relative">
                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                            <div class="w-full border-t border-gray-300" />
                        </div>
                        <div class="relative flex justify-center">
                            <span class="bg-white px-2 text-sm text-gray-500"></span>
                        </div>
                    </div>

                     <!-- country -->
                    <div class="px-6 mt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Kraj</label>
                        <div class="mt-1 sm:col-span-2">
                            <div class="w-auto">
                                <Listbox as="div" v-model="manufacturer.location.country" >
                                    <div class="relative">
                                        <ListboxButton :key="manufacturer.location.country" class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                            <span class="flex items-center">
                                                <img :src="manufacturer.location.country?.flags?.png" class="h-6 w-6 flex-shrink-0 rounded-full border-gray-300 border-2" />
                                                <span class="ml-3 block truncate">{{manufacturer.location.country?.translations?.pol?.common !== undefined ? manufacturer.location.country?.translations?.pol?.common :  manufacturer.location.country?.translations?.name?.common}}</span>
                                            </span>
                                            <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                                <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </span>
                                        </ListboxButton>

                                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                            <ListboxOptions class="absolute z-10 mt-1 max-h-56  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                <ListboxOption as="template" v-for="country in countries" :key="country.translations.pol.common " :value="country" v-slot="{ active, selected }">
                                                    <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                        <div class="flex w-full items-center">
                                                            <img :src="country.flags.png" class="h-6 w-6 flex-shrink-0 rounded-full" />
                                                            <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{country.translations.pol.common !== undefined ? country.translations.pol.common :  country.name.common}}</span>
                                                        </div>

                                                        <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    </li>
                                                </ListboxOption>
                                            </ListboxOptions>
                                        </transition>
                                    </div>
                                </Listbox>
                            </div>
                        </div>
                    </div>

                     <!-- city -->
                    <div class="px-6 mt-5">
                        <label for="city" class="block text-sm font-medium text-gray-700">Miasto</label>
                        <div class="mt-1">
                            <input  v-model="manufacturer.location.city" type="text" name="city" id="city" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                    </div>

                     <!-- postcode -->
                    <div class="px-6 mt-5 w-1/2">
                        <label for="city" class="block text-sm font-medium text-gray-700">Kod pocztowy</label>
                        <div class="mt-1">
                            <input  v-model="manufacturer.location.postcode" type="text" name="postcode" id="postcode" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                        <p v-if="manufacturer.location.country?.postalCode !== undefined" class=" select-none text-xs text-gray-500">{{manufacturer.location.country?.postalCode?.format !== undefined ? 'Sugerowany format: '+ manufacturer.location.country?.postalCode?.format : 'Brak'}}
                        </p>
                    </div>

                    <!-- address -->
                    <div class="px-6 mt-5">
                        <label for="address" class="block text-sm font-medium text-gray-700">Address</label>
                        <div class="mt-1">
                            <input  v-model="manufacturer.location.address" type="text" name="address" id="address" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                    </div>
                </div>

                <!-- Deleting manufacturers temporary disabled -->
                <!-- <div class="sm:px-6 mt-4">
                    <div class="mb-3 font-medium text-red-500">Usuń producenta</div>
                    <div class="flex items-center mt-1 mb-4">
                      <input id="delete-route-checkbox" v-model="deleteCheckbox" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500">
                      <label for="delete-route-checkbox" class="text-xs ml-2 select-none text-">Chcę usunąć tego producenta - wiem co robię.</label>
                    </div>
                  </div> -->
                </div>
                <div class="flex flex-shrink-0 justify-end px-4 py-4 items-center">
                  <div v-if="!loading">
                    <button @click.prevent="emitClose" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Zamknij</button>
                    <button v-if="!deleteCheckbox" @click.prevent="updateManufacturer()" class="ml-2 rounded-md border border-gray-300  py-2 px-4 text-sm font-medium text-gray-700 shadow-sm  bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Aktualizuj</button>
                    <button v-else @click.prevent="deleteManufacturer()" type="button" class="ml-2 inline-flex items-center rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Usuń producenta </button>
                  </div>    
                  <div v-else>
                    <p>Ładowanie...</p>
                  </div>
                </div>
              
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
    import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
    import { ChevronDownIcon, CheckIcon } from '@heroicons/vue/outline'
    import { db } from "@/firebase/gfbconf.js";
    import axios from 'axios';
    import moment from 'moment';

    export default {
        name: "ManufacturersManageModal",
        components: {
            Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions,
            ChevronDownIcon, CheckIcon
        },
        props: ["item"],
        data () {
            return {
                loading: false,
                open: false,
                deleteCheckbox: false,
                manufacturers: [],
                countries: [],
                selectedManufacturer: null,
                phonePrefixes: [],
                manufacturer: JSON.parse(JSON.stringify(this.item)),
                oldEmail: JSON.parse(JSON.stringify(this.item.email)),
                errors: {
                    name: null,
                    email: null,
                }
            }
        },
        methods: {
            emitClose() {
              this.$emit("close");
            },
            async getCountries() {
                this.loading = true;
                this.countries = [];
                const res = await axios.get(`${this.$store.state.apiLink}/data/getCountries`)
                if(res.data.status === 'SUCCESS')
                {
                    res.data.countries.forEach(country => {
                        this.countries.push(country);
                    });
                    
                    this.countries.forEach(country => {
                        const prefix = country.phonePrefixes[0];
                        const name = country.translations.pol.common != null ? country.translations.pol.common : country.name;
                        
                        if(country.name === this.manufacturer.location.country) {
                            this.manufacturer.phone.prefix = prefix;
                            this.manufacturer.location.country = country;
                        }
                        
                        // Check again to be sure
                        this.phonePrefixes.push({
                            prefix: prefix,
                            name: name,
                            ref: country
                        });

                    });
                };
                this.loading = false;
            },
            clearErrors() {
                this.errors.name = null,
                this.errors.email = null
            },
            async validate() {
                this.clearErrors()
                let validationSuccess = true
                const { name, email } = this.manufacturer

                if(!name || !name.length) {
                    this.errors.name = "Nazwa jest wymagana"
                    validationSuccess = false
                }

                if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
                    this.errors.email = "Sprawdź poprawność adresu email"
                    validationSuccess = false
                }
                if(!email || !email.length) {
                    this.errors.email = "Email jest wymagany"
                    validationSuccess = false
                } 

                //check if a manufacturer with that email exists
                if((await db.collection("Manufacturers").where("email", "==", email).get()).docs.length > 0 && email !== this.oldEmail) {
                    this.errors.email = "Producent z takim adresem email już istnieje."
                    validationSuccess = false
                }

                return validationSuccess
            },
            async updateManufacturer() {
                this.loading = true
                try {
                    if(!await this.validate()) {
                        this.loading = false
                        return
                    }

                    const { name, email } = this.manufacturer
                    const { prefix, number } = this.manufacturer.phone
                    const { country, postcode, address, city } = this.manufacturer.location

                    let manufacturerDoc = await db.collection("Manufacturers").doc(this.manufacturer.id) 

                    await manufacturerDoc.update({
                        name,
                        email,
                        phone: {
                            prefix,
                            number,
                            full: `${prefix}${number === null ? '' : number}`
                        },
                        location: {
                            country: country.name.common,
                            postcode,
                            address,
                            city
                        },
                        "meta.lastModificationDate": moment().toDate()
                    })

                    if(this.manufacturer.elasticSearch.docId.length>0) {
                        const document = {
                            id: manufacturerDoc.id,
                            names: [name],
                            emails: [email],
                            phones: [`${prefix}${number === null ? '' : number}`],
                            countries: [country.name.common],
                            cities: [city],
                            postcodes: [postcode],
                            addresses: [address]
                        };

                        await axios.post(`${this.$store.state.apiLink}/elastic/update`, {
                            index: "jan-art-manufacturers",
                            elasticId:  this.manufacturer.elasticSearch.docId,
                            document: document
                        })
                    }

                    // this.$router.push("/dashboard/manufacturers/list"); 
                    this.loading = false
                    this.emitClose()

                    this.$store.commit('setNotification', {
                        show: true,
                        head: "Producent zaktualizowany.",
                        subheader: `Producent został pomyślnie zaktualizowany.`,
                        success: true
                    });
                } catch(error) {
                    this.loading = false

                    this.$store.commit('setNotification', {
                        show: true,
                        head: "Nie udało się zaktualizować producenta.",
                        subheader: `Nie udało się zaktualizować producenta.`,
                        success: false
                    });

                    console.log(error);
                }
                
            },
            async deleteManufacturer() {
                this.loading = true
                try {
                    const manufacturer = db.collection("Manufacturers").doc(this.manufacturer.id)
                    manufacturer.delete()

                    //delete a doc from an elastic search
                    if(this.manufacturer.elasticSearch.docId.length>0) {
                        const res = await axios.post(`${this.$store.state.apiLink}/elastic/delete`, {
                            index: "jan-art-manufacturers",
                            id: this.manufacturer.elasticSearch.docId
                        }).catch((error) => {
                            console.log(error);
                        })
                    }

                    this.emitClose()

                    //set a success notification
                    this.$store.commit('setNotification', {
                        show: true,
                        head: "Producent usunięty.",
                        subheader: `Producent został usunięty z listy.`,
                        success: true
                    });

                    this.loading = false
                } catch (error) {
                    //set a fail notification
                    this.$store.commit('setNotification', {
                        show: true,
                        head: "Nie udało się usunąć producenta.",
                        subheader: `Nie udało się usunąć producenta z listy.`,
                        success: false
                    });

                    console.log(error);
                    this.loading = false
                }
            }
        },
        created() {
            this.getCountries()
        }
    };
</script>