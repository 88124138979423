<template>
    <ManufacturersManageModal @close="modal.open = false" :item="modal.item" v-if="modal.open"></ManufacturersManageModal>
   <div>
        <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li class="flex">
                <div class="flex items-center">
                <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                </router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <router-link to="/dashboard/storage" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł magazyn</router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Lista producentów</div>
                </div>
            </li>
            </ol>
        </nav>
        <router-link to="/dashboard/storage" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>

        <!-- SEARCHER -->
        <div class="min-w-0 flex-1 xl:col-span-6">
            <div class="flex items-center justify-between pr-6 xl:pr-0">
                <div class="w-2/3 sm:w-1/3 flex">
                    <label for="search" class="sr-only">Szukaj</label>
                    <div class="relative">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input :disabled="this.searcher.locked" v-model="searcher.phrase" id="search" name="search" :class="['block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm', this.searcher.locked ? 'opacity-50' : '']" placeholder="Wyszukaj" type="search" />
                    </div>
                    <button :disabled="this.searcher.locked" type="button" @click.prevent="search" :class="['transition ease-in-out duration-300 ml-2 inline-flex items-center rounded border border-transparent bg-blue-100 px-2.5 py-1.5 text-xs font-medium text-blue-700', this.searcher.locked || this.searcher.phrase.length === 0 ? 'opacity-50 cursor-default' : 'hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']">Szukaj</button>
                </div>
                <router-link to="add" class="inline-flex items-center rounded-full border border-transparent bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <PlusSmIcon class="h-6 w-6" aria-hidden="true" />
            </router-link>
            </div>
            
            <div class="mt-4" v-if="searcher.found.length > 0">
                <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-700">
                    Wynik wyszukiwania dla frazy: "{{searcher.lastCall.phrase}}" (Znaleziono: {{searcher.found.length}})
                    <button @click.prevent="resetSearch"  type="button" class="transition ease-in-out duration-300 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white">
                        <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                    </button>
                </span>           
            </div>
        </div>

        <div class="mt-4 flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 xl:px-0">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle mx-auto px-4 sm:px-6 lg:grid lg:max-w-7xl lg:gap-8 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg" v-if="!loading">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Nazwa producenta</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Telefon</th>
                                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                        <span class="sr-only">Edycja</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="manufacturer in searcher.found.length > 0 ? searcher.found : manufacturers" :key="manufacturer.id">
                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                        {{manufacturer.name}}
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs">
                                        <a :class="[manufacturer.email !== null ? '' : 'pointer-events-none', 'tranistion-all duration-300 text-gray-500 hover:text-indigo-600 font-medium']" :href="`${manufacturer.email !== null ? `mailto:${manufacturer.email}` : ''}`">{{manufacturer.email !== null ? manufacturer.email : 'Brak'}}</a>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                                        <a class="tranistion-all duration-300 text-gray-500 hover:text-indigo-600 font-medium" :href="`${manufacturer.phone.full !== null ? `tel:${manufacturer.phone.full}` : 'Brak'}`">{{manufacturer.phone.full !== null ? manufacturer.phone.full : 'Brak'}}</a>
                                    </td>
                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-6">
                                        <button @click="modal.open = true, modal.item = manufacturer" class="transition ease-in-out duration-300 -ml-px relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">Zarządzaj</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <EmptyState class="mt-2 mb-2" v-if="manufacturers.length === 0"></EmptyState>
        <div class="flex items-center justify-center mt-4" v-if="queryLimitReached === false">
            <button type="button" @click.prevent="loadMoreProducts" class="transition ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Załaduj więcej..</button>
        </div>    
    </div>
</template>

<script>
import { HomeIcon } from '@heroicons/vue/solid'
import {db} from "@/firebase/gfbconf.js";
import { SearchIcon, PlusSmIcon, XIcon  } from '@heroicons/vue/outline';
import EmptyState from '../components/EmptyState.vue'
import axios from 'axios';
import ManufacturersManageModal from '../components/ManufacturersManageModal.vue';

 export default {
    data() {
        return {
            loading: false,
            searcher: {
                found: [],
                locked: false,
                phrase: "",
                lastCall: null
            },
            modal: {
                open: false,
                item: null,
            },
            manufacturers: [],
            queryLimit: 25,
            dbListener: null,
            lastLoaded: null,
            queryLimitReached: false
        }
    },
    components: {
        EmptyState,
        HomeIcon,
        SearchIcon, PlusSmIcon, XIcon,
        ManufacturersManageModal
    },
    methods: {
        getManufacturers() {
            this.dbListener = db.collection("Manufacturers").orderBy("meta.createdDate", "desc").limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.manufacturers = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                    this.manufacturers.push(doc.data());
                    this.lastLoaded = doc.data().meta.createdDate;
                });
            });
        },
        resetSearch()
        {
            this.searcher.found = [];
            this.searcher.lastCall = null;
            this.searcher.phrase = "";
            this.getManufacturers()
        },
        async search()
        {
            if(this.searcher.phrase.length > 0)
            {
                this.searcher.locked = true;
                const res = await axios.post(`${this.$store.state.apiLink}/search`, {
                    index: "jan-art-manufacturers",
                    collection: "Manufacturers",
                    phrase: this.searcher.phrase,
                    searchFields: ['id', 'names', 'emails', 'phones', 'countries', 'cities', 'postcodes', 'addresses']
                })

                if(res.data.success)
                {
                    this.searcher.found = res.data.found;
                    if(res.data.found.length > 0)
                    {
                        this.searcher.lastCall = res.data.data;
                        this.dbListener()
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Wyszukiwanie zakończone!",
                            subheader: `Znaleziono ${res.data.found.length} producentów.`,
                            success: true
                        });
                    }else{
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Nie znaleziono wyników!",
                            subheader: `Spróbuj wyszukać inną frazę.`,
                            success: false
                        });
                    }

                }
                else
                {
                    this.$store.commit('setNotification', {
                        show: true,
                        head: "Wyszukiwanie zakończone niepowodzeniem!",
                        subheader: `Coś poszło nie tak...`,
                        success: false
                    });
                }
                setTimeout(() =>
                {
                    // this.searcher.phrase = "";
                    this.searcher.locked = false;
                }, 3000)
            }
            else
            {
                return;
            }
        },
        continueListening()
        {
            this.queryLimit = this.queryLimit + 10;
            this.getManufacturers()
        },
        loadMoreProducts()
        {
            this.dbListener();
            this.continueListening();      
        },
    },
    async created()
    {
        if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.products)
        {
            this.$router.push("/hub")
        }
        this.getManufacturers()
    },
 }
</script>